import React, { useEffect, useState } from "react";
import aos from "aos";
import "aos/dist/aos.css";
import { FaPhoneAlt } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import "./contact.css";
import firebase from "firebase";
import { db } from "../../firebase/Firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
function OrderForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    aos.init({ duration: 1500, offset: 200 });
  });

  function postOrder(e) {
    e.preventDefault();
    if (!name || !email || !number) {
      return toast.error("Enter all fields", { autoClose: 2000 });
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      return toast.error("Invalid Email", { autoClose: 2000 });
    } else {
      db.collection("OrderQueries")
        .add({
          name: name,
          email: email,
          time: firebase.firestore.FieldValue.serverTimestamp(),
          number: number,
        })
        .then(() => {
          toast.success("Thank you.", { autoClose: 2000 });
        })
        .catch((err) => {
          alert(err.message);
        });
      setName("");
      setEmail("");
      setNumber("");
    }
  }

  return (
    <div className="contact  white-text " id="order">
      <div className="form">
        <h4 className="heading">PRE-ORDER NOW</h4>
        <h5
          style={{
            color: "#bdbdbd",
            margin: "35px 0px",
            fontFamily: "'Open Sans', sans-serif",
          }}
        >
          Please fill out the form below and we will get back to you as soon as
          possible.
        </h5>
        <div style={{ width: "100%", textAlign: "left" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <input
              type="text"
              className="grey lighten-3 input1"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              className="grey lighten-3 input1"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="number"
              className="grey lighten-3 input1"
              placeholder="Number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <button
            className="btn waves-effect grey darken-4 "
            onClick={postOrder}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="info">
        <h5 id="contactInfo">Contact Us</h5>
        <p className="infoPara">
          <span className="icons">
            <FaPhoneAlt />
          </span>
          <span className="infolabel"> Phone </span>
          <span className="infoText">
            <br />
            +91 8249158968
            <br />
            +91 8093644586
          </span>
        </p>
        <p className="infoPara">
          <span className="icons">
            <FiMail />
          </span>
          <span className="infolabel"> Email </span>
          <span className="infoText">
            <br />
            info@auum.in
          </span>
        </p>
      </div>
    </div>
  );
}

export default OrderForm;
