import React, { useEffect, useState } from "react";
import "./careers.css";
import JobCard from "../../components/JobCard/JobCard";
import { db } from "../../firebase/Firebase";
import { job} from "../../assets/images/index";
import LoadingText from "../Loading/LoadingText";

const Careers = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);
    db.collection("JobOpenings").onSnapshot((snapshot) => {
      const j = [];
      snapshot.forEach((item) =>
        j.push({
          id: item.id,
          title: item.data().title,
          postedOn: new Date(
            item.data().postedon.seconds * 1000
          ).toLocaleDateString(),
          type: item.data().type,
          domain: item.data().domain,
        })
      );
      setJobs(j);
    });
  }, []);
  return (
    <div className="career-page">
      <div className="standard-div join-us ">
        <div className="sub-div sub-div-1">
          <div>
            <h4
              style={{ marginLeft: 0, marginBottom: "15px", fontSize: "50px" }}
            >
              Join Us
            </h4>
            <p style={{ fontSize: "19px" }}>
              “As we enter into a new decade, we face new challenges. Each new
              challenge is an opportunity in disguise that can revolutionize the
              world. Join AUUM to be a part of that journey & help us drive
              innovation & technology to bring meaningful change to the world. ”
            </p>
          </div>
        </div>
        <div className="sub-div  sub-div-2">
          <img src={job} style={{ width: "100%" }} alt="" />
        </div>
      </div>
      <div className="standard-div white-text">
        <h4 style={{ fontSize: "50px", marginBottom: "10px" }}>
          We are looking for ...
        </h4>
        <p style={{ fontSize: "19px" }}>
          <b style={{ fontSize: "25px" }}>
            {" "}
            Curious. Creative. Innovation-obsessed. #IWantToChangeTheWorld.
          </b>{" "}
          <br />
          We search for talented people who each bring unique skills and
          behaviours that will help us build AUUM. Whether you’re in marketing,
          finance, community-facing roles or in our coveted technology
          development, our people bring relentless passion, fast learning and a
          culture of innovation to every dimension of their work. Every member
          of our team is open to new perspectives, willing to navigate
          uncertainty and brings humility and radical candour to the table at
          all times.
        </p>
      </div>
      <div className="job-openings standard-div">
        <div className="heading">
          {" "}
          <h4 style={{ fontSize: "40px", marginRight: "20px" }}>
            Job Opennings
          </h4>
          <h4 className="sub-text">( {jobs.length} jobs found )</h4>{" "}
        </div>
        {jobs.length === 0 ? (
          <LoadingText text="Currently Not Hiring" />
        ) : (
          jobs.map((job) => {
            return (
              <JobCard
                id={job.id}
                title={job.title}
                domain={job.domain}
                postedOn={job.postedOn}
                type={job.type}
              />
            );
          })
        )}
        {}
      </div>
    </div>
  );
};
export default Careers;
