import React, { useEffect } from "react";
import "aos/dist/aos.css";
import aos from "aos";
import { FaAngleDown } from "react-icons/fa";
/* import { Link as Link1} from "react-router-dom"; */
import { Link as Link2 } from "react-scroll";
import "@brainhubeu/react-carousel/lib/style.css";
import "./Swasner.css";
import OrderForm from "../contact/OrderContact";
import Brochure from "../../assets/Brochure/Brochure.pdf";

import { img1, img2, img3, img4, img5, img6, img7 } from "../../assets/images";

const Swasner = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    aos.init({ duration: 1000, offset: 300 });
  });
  return (
    <>
      <Link2
        activeClass="active"
        to="next"
        spy={true}
        smooth={true}
        duration={500}
        style={{ cursor: "pointer" }}
      >
        <div class="arrow bounce">
          <FaAngleDown />
        </div>
      </Link2>
      <div className="Swasner-page">
        <div className=".swasner-container">
          <div className="swasner one" data-aos="fade-up">
            <img src={img1} className="swasnerImg" alt="Swasner" />
            <div className="content ">
              <h4 style={{color:"gray",fontWeight:"400",}} >Introducing</h4>
              <h4  style={{color:"white",fontSize:"5rem"}} >Swasner</h4>
              <h4  style={{color:"white"}} >A Next Generation PPE</h4>
              <div style={{ marginTop:"2rem" }} >
              <Link2
                activeClass="active"
                to="order"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <button className="btn swasner-page-btn">Pre-Order</button>
              </Link2>
              <a href={Brochure} target="_blank" rel="noreferrer">
                <button className="know-btn">Brochure</button>
              </a>
              </div>
            </div>
          </div>
          <div className="swasner two " data-aos="fade-right" id="next">
            <img src={img2} className="swasnerImg" alt="Swasner" />
            <div className="content">
              <h4>Face Shield</h4>
              <p>
                Enhance your protection with a highly comfortable and
                unbreakable <span>face shield</span>.
              </p>
            </div>
          </div>
          <div className="swasner three " data-aos="fade-left">
            <img src={img3} className="swasnerImg" alt="Swasner" />
            <div className="content">
              <h4>Quaint but Enticing</h4>
              <p>
                Designed ergonomically, it also caters to aesthetic needs of the
                users making it appear akin to just another hoodie.
              </p>
            </div>
          </div>
          <div className="swasner four" data-aos="fade-right">
            <img src={img4} className="swasnerImg" alt="Swasner" />
            <div className="content">
              <h4>End of Sweating</h4>
              <p>
                This next gen PPE Comes with the best in class fan, barring 100,000+ hours of running.
              </p>
            </div>
          </div>
          <div className="swasner five" data-aos="fade-right">
            <img src={img5} className="swasnerImg" alt="Swasner" />
            <div className="content">
              <h4>High Standard protection</h4>
              <p>
                Swasner is designed in a way to filter air and safeguard user's
                personal space with KN95 protection, enabling the user to
                breathe purified air.
              </p>
            </div>
          </div>
          <div className="swasner six" data-aos="fade-left">
            <img src={img6} className="swasnerImg" alt="Swasner" />
            <div className="content">
              <p>
                <div className="content">
                  <h4>Perpetual in Nature</h4>
                  <p>
                    Comes with a stupendous battery life of more than 8 hours,
                    it proves to be economical in the long run.
                  </p>
                </div>
              </p>
            </div>
          </div>
          <div className="swasner seven" data-aos="fade-up">
            <img src={img7} className="swasnerImg" alt="Swasner" />
            <div className="content">
              <h4>THE ULTIMATE SWASNER PPE</h4>
              <p style={{ lineHeight: "50px", marginLeft:"30px"  }}>
                <ul type="circle">
                  <li>First of its kind</li>
                  <li>Over all Protection</li>
                  <li>Modernized for an active life</li>
                  <li>Fashionable yet Elegant</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <OrderForm/>
      </div>
    </>
  );
};
export default Swasner;
