import React from "react";
import NavBar from "./Header/Navbar";
import Footer from "./Footer/Footer";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./screens/home/home";
import Swasner from "./screens/swasner/Swasner";
import SwasnerResearch from "./screens/research/SwasnerResearch";
import BubbleHelmetResearch from "./screens/research/BubbleHelmetResearch";
import Policy from "./screens/Policy/policy";
import Products from "./screens/Products/Products";
import Contact from "./screens/contact/contact";
import OrderPage from "./screens/contact/orderPage";
import errorFourOFour from "./screens/errorFourOFour";
import Careers from "./screens/career/Careers";
import JobDescription from "./screens/career/JobDescription";
import ApplicationForm from "./screens/career/ApplicationForm";
import SubmittingScreen from "./screens/Loading/submittingScreen";
import Thankyou from "./screens/Thankyou/Thankyou";

const Routing = () => {
  return (
    <Switch>
      <Route exact={true} path="/" component={Home} />
      <Route path="/home" component={Home} />
      <Route path="/swasner" component={Swasner} />
      <Route path="/swasnerresearch" component={SwasnerResearch} />
      <Route path="/bubblehelmetresearch" component={BubbleHelmetResearch} />
      <Route path="/careers" component={Careers} />
      <Route path="/jobdescription/:jobid" component={JobDescription} />
      <Route path="/jobapplicationform/:role" component={ApplicationForm} />
      <Route path="/submitting" component={SubmittingScreen}/>
      <Route path="/thankyou" component={Thankyou}/>
      <Route path="/policy" component={Policy} />
      <Route path="/products" component={Products} />
      <Route path="/orderpage" component={OrderPage} />
      <Route path="/contactus" component={Contact} />
      <Route path="*" component={errorFourOFour}/>
    </Switch>
  );
};
function App() {
  return (
    <BrowserRouter>
      <div>
        <NavBar />

        <Routing />

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
