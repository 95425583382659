import React , {useEffect} from "react";
import {short_logo} from "../../assets/images/index"

const LoadingScreen = () => {
  useEffect(() => {
    window.scroll(0,0)
  }, [])
  return (
    <div
      style={{
        display: "flex",
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "80vw",
        margin: "0 auto",
      }}
    >
      <img style={{width:"200px", margin:"30px"}} src={short_logo} alt=""/>
      <h1 style={{ color: "white" }}>Loading...</h1>
    </div>
  );
};

export default LoadingScreen;
