import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { ImTwitter } from "react-icons/im";
import { FaLinkedin } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa"
/* import {FiMail} from "react-icons/fi" */
import logo from "../assets/AUUM.logo.static/AUUM1.png";

function Footer() {
  return (
    <div class="page-footer footer black">
      <div className="footer-container">
        <div>
          <div className="footer-logo">
            <img
              src={logo}
              height="45rem"
              style={{ paddingTop: "0.5rem", margin: "10px" }}
              alt="AUUM Logo"
            />
          </div>
          <div class="socialMedia">
            <a
              href="https://www.facebook.com/auumplatforms/"
              target="_blank"
              rel="noreferrer"
              className="socialMediaIcon"
            >
              <FaFacebook />
            </a>
            <a
              href="https://www.instagram.com/auumplatforms/"
              target="_blank"
              rel="noreferrer"
              className="socialMediaIcon"
            >
              <FaInstagram />
            </a>
            <a
              href="https://twitter.com/AUUM_Tech?s=20"
              target="_blank"
              rel="noreferrer"
              className="socialMediaIcon"
            >
              <ImTwitter />
            </a>
            <a
              href="https://www.linkedin.com/company/auum-technologies"
              target="_blank"
              rel="noreferrer"
              className="socialMediaIcon"
            >
              <FaLinkedin />
            </a>
             <a
              href="https://wa.me/918249158968"
              target="_blank"
              rel="noreferrer"
              className="socialMediaIcon"
            >
              <FaWhatsapp/>
            </a>
            {/* <a href="mailto:info@auum.in" target="_blank"><FiMail/></a> */}
          </div>
        </div>
        <div className="footer-content">
          <div class="footer-links-container">
          <h5>Quick Links</h5>
            <Link to="/home" className="footer-links">
              Home
            </Link>
            <Link to="/swasner" className="footer-links">
              Swasner
            </Link>
            <Link to="/careers" className="footer-links">
              Careers
            </Link>
          </div>
          <div class="footer-links-container" style={{alignSelf:"start"}} >
          <h5>Legal</h5>
            <Link to="/policy" className="footer-links">
              Privacy Policy
            </Link>
          </div>
          <div class="footer-links-container">
          <h5>Help</h5>
            <Link to="/contactus" className="footer-links">
              Contact Us
            </Link>
          </div>
        </div>
      </div>

      <div class="footer-copyright">
        <div class="container" style={{ color: "grey", marginBottom: "20px", width:"95vw" }}>
          © Copyright 2021 AUUM Platforms Private Limited. All Rights Reserved
        </div>
      </div>
    </div>
  );
}

export default Footer;
