import React from "react"
const Introduction =()=>{
    return(
        <div id="Introduction">
        <h3> 1. Introduction </h3>
       <p>
       <ol type="i">
          <li>
            These terms and conditions shall govern your use of our
            website.
          </li>
          <li>
            By using our website, you accept these terms and conditions
            in full; accordingly, if you disagree with these terms and
            conditions or any part of these terms and conditions, you
            must not use our website.
          </li>
          <li>
            If you register with our website, submit any material to our
            website or use any of our website services, we will ask you
            to expressly agree to these terms and conditions.
          </li>
          <li>
            You must be at least 18 years of age to use our website; by
            using our website or agreeing to these terms and conditions,
            you warrant and represent to us that you are at least 18
            years of age.
          </li>
          <li>
            Our website uses cookies; by using our website or agreeing
            to these terms and conditions, you consent to our use of
            cookies in accordance with the terms of our privacy and
            cookies policy.
          </li>
        </ol>
       </p>

      </div>
    )
}

export default Introduction