import React from "react";
<style>
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
</style>

export default function errorFourOFour() {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection:"column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
        <h1 style={{color:"white" , fontSize:"250px" , fontFamily:" 'Dancing Script', cursive"}}>4 0 4</h1>
        <h3 style={{ fontFamily:" 'Dancing Script', cursive" , fontSize:"60px"}}>Oops ! Page not found</h3>
    </div>
  );
}
