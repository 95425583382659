import React from "react";

const Assignment = () => {
  return (
    <div id="Assignment">
      <h3 id="eight">8. Assignment</h3>

      <p>
        <ol type="i">
          <li>
            You hereby agree that we may assign, transfer, sub-contract or
            otherwise deal with our rights and/or obligations under these terms
            and conditions.
          </li>
          <li>
            You may not without our prior written consent assign, transfer,
            sub-contract or otherwise deal with any of your rights and/or
            obligations under these terms and conditions.
          </li>
        </ol>
      </p>
    </div>
  );
};
export default Assignment;
