import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import GoToTop from "./goToTop";
import logo from "../assets/AUUM.logo.static/AUUM1.png";
import "./NavBar.css";

const NavBar = () => {
  const [click, setClick] = useState(false);
  const [navBar, setNavBar] = useState(false);
  const handleClick = () => setClick(!click);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);
  return (
    <nav className={navBar ? "navbarX " : "navbar"}>
      <div className="nav-container">
        <NavLink exact to="/" className="nav-logo">
          <img
            src={logo}
            height="48rem"
            style={{ paddingTop: "1rem" }}
            alt="Auum logo"
          />
        </NavLink>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-items">
            <NavLink
              to="/home"
              className="nav-links"
              activeClassName="active"
              onClick={handleClick}
            >
              home
            </NavLink>
          </li>
          <li className="nav-items">
            <NavLink
              to="/products"
              className="nav-links"
              activeClassName="active"
              onClick={handleClick}
            >
              Products
            </NavLink>
          </li>
          <li className="nav-items">
            <NavLink
              to="/careers"
              className="nav-links"
              activeClassName="active"
              onClick={handleClick}
            >
              Careers
            </NavLink>
          </li>
          <li className="nav-items dropDown">
            <span className="nav-links" activeClassName="active">
              Our Research
            </span>
            <ul className="sub-nav-menu">
              <li className="nav-items sub-nav-items">
                <NavLink
                  to="/swasnerresearch"
                  className="nav-links "
                  activeClassName="active"
                  onClick={handleClick}
                >
                  Swasner PPE
                </NavLink>
              </li>
              <li className="nav-items sub-nav-items">
                <NavLink
                  to="/bubblehelmetresearch"
                  className="nav-links "
                  activeClassName="active"
                  onClick={handleClick}
                >
                  Bubble Helmet
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="nav-items">
            <NavLink
              to="/contactus"
              className="nav-links"
              activeClassName="active"
              onClick={handleClick}
            >
              Contact us
            </NavLink>
          </li>
        </ul>
        <GoToTop />
        <div className="nav-icon" onClick={handleClick}>
          {click ? <FaTimes /> : <FaBars />}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
