import React, { useEffect } from "react";
import { Link} from "react-scroll";
import Introduction from "./contents/Introduction";
import Copyright from "./contents/Copyright";
import LicenseToUseWebsite from "./contents/LicenseToUseWebsite";
import AcceptableUse from "./contents/AcceptableUse";
import LimitedWarranties from "./contents/LimitedWarranties";
import Limitations from "./contents/Limitations";
import Breaches from "./contents/Breaches";
import Assignment from "./contents/Assignment";
import Severability from "./contents/Severability";
import ThirdPartyRights from "./contents/ThirdPartyRights";
import EntireAgreement from "./contents/EntireAgreement";
import LawAndJurisdiction from "./contents/LawAndJurisdiction";
import OurDetails from "./contents/OurDetails";
import "./policy.css";

const Policy = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
 }) 
  return (
    <div className="Policy-page">
      <div style={{ display: "flex" }}>
        <div class="onPage">
          {/* <h5 style={{ color: "white", textAlign: "center" }}>
              On this page
            </h5> */}
          <ol type="1">
            <li>
              <Link
                activeClass="active"
                to="Introduction"
                spy={true}
                smooth={true}
                offset={-200}
                duration={500}
              >
                Introduction
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="Copyright"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Copyright notice
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="LicenseToUseWebsite"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                License to use website
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="AcceptableUse"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
              >
                Acceptable Use
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="LimitedWarranties"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
              >
                Limited Warranties
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="Limitations"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Limitations and exclusions of liability
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="Breaches"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Breaches
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="Assignment"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Assignment
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="Severability"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Severability
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="ThirdPartyRights"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Third party rights
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="EntireAgreement"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Entire agreement
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="LawAndJurisdiction"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Law and jurisdiction
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="OurDetails"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Our details
              </Link>
            </li>
          </ol>
        </div>
        <div class="Policy">
          <h1>Terms and conditions of use</h1>
          <Introduction/>
          <Copyright />
          <LicenseToUseWebsite />
          <AcceptableUse />
          <LimitedWarranties />
          <Limitations />
          <Breaches />
          <Assignment />
          <Severability />
          <ThirdPartyRights />
          <EntireAgreement />
          <LawAndJurisdiction />
          <OurDetails />
        </div>
      </div>
    </div>
  );
};
export default Policy;
