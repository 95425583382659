import React from "react";
const LicenseToUseWebsite = () => {
  return (
    <div id="LicenseToUseWebsite">
      <h3 id="two">3. License to use website</h3>

      <p>
        <ol type="i">
          <li>You may:</li>
          <ol type="a" className="typeAlist">
            <li>view pages from our website in a web browser;</li>
            <li>
              download pages from our website for caching in a web browser;
            </li>
            <li>print pages from our website;</li>
            <b>
              subject to the other provisions of these terms and conditions.
            </b>
          </ol>
          <li>
            Except as expressly permitted by <b>Section 1</b> or the other
            provisions of these terms and conditions, you must not download any
            material from our website or save any such material to your
            computer.
          </li>
          <li>
            You may only use our website for your own personal and business
            purposes, and you must not use our website for any other purposes.
          </li>
          <li>
            Except as expressly permitted by these terms and conditions, you
            must not edit or otherwise modify any material on our website.
          </li>
          <li>
            Unless you own or control the relevant rights in the material, you
            must not:
          </li>
          <ol type="a" className="typeAlist">
            <li>
              republish material from our website (including republication on
              another website);
            </li>
            <li>sell, rent or sub-license material from our website;</li>
            <li>show any material from our website in public;</li>
            <li>
              exploit material from our website for a commercial purpose; or
            </li>
            <li>redistribute material from our website.</li>
          </ol>
          <li>
            Notwithstanding <b>Section v </b>, you may redistribute our
            newsletter in print and electronic form to any person.
          </li>
          <li>
            We reserve the right to restrict access to areas of our website, or
            indeed our whole website, at our discretion; you must not circumvent
            or bypass, or attempt to circumvent or bypass, any access
            restriction measures on our website.
          </li>
          <li>We may revise these terms and conditions from time to time.</li>
          <li>
            The revised terms and conditions shall apply to the use of our
            website from the date of publication of the revised terms and
            conditions on the website, and you hereby waive any right you may
            otherwise have to be notified of, or to consent to, revisions of
            these terms and conditions. OR We will give you written notice of
            any revision of these terms and conditions, and the revised terms
            and conditions will apply to the use of our website from the date
            that we give you such notice; if you do not agree to the revised
            terms and conditions, you must stop using our website.
          </li>
          <li>
            If you have given your express agreement to these terms and
            conditions, we will ask for your express agreement to any revision
            of these terms and conditions; and if you do not give your express
            agreement to the revised terms and conditions within such period as
            we may specify, we will disable or delete your account on the
            website, and you must stop using the website.
          </li>
        </ol>
      </p>
    </div>
  );
};

export default LicenseToUseWebsite;
