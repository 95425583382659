import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./ApplicationForm.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firebase from "firebase";
import { db, storage } from "../../firebase/Firebase";
import {bg} from "../../assets/images/index";
toast.configure();

const ApplicationForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [alternateEmail, setAlternateEmail] = useState("");
  const [number, setNumber] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [address, setAddress] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [city, setCity] = useState("");
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [onSite, setOnSite] = useState(false);
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [file, setFile] = useState(null);
  const hiddenFileInput = React.useRef(null);
  const history = useHistory();
  const { role } = useParams();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = () => {
    const uploadTask = storage.ref(`Resumes/${file.name}`).put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("Resumes")
          .child(file.name)
          .getDownloadURL()
          .then((url) => {
            db.collection("JobApplications")
              .add({
                name: {
                  firstName: firstName,
                  lastName: lastName,
                },
                appliedFor: role,
                email: email,
                alternateEmail: alternateEmail,
                number: number,
                alternateNumber: alternateNumber,
                time: firebase.firestore.FieldValue.serverTimestamp(),
                address: address,
                permanentAddress: permanentAddress,
                city: city,
                answer1: answer1,
                answer2: answer2,
                onSite: onSite,
                resume: url.toString(),
              })
              .then(() => {
                toast.success("Submitted Successfully.", { autoClose: 2000 });
                history.push("/thankyou");
              })
              .catch((err) => {
                alert(err.message);
              });
          });
      }
    );
  };

  const postData = (e) => {
    e.preventDefault();
    if (
      !firstName ||
      !email ||
      !number ||
      !file ||
      !address ||
      !answer2 ||
      !answer1 ||
      !city
    ) {
      toast.error("Enter all the fields.", { autoClose: 2000 });
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      return toast.error("Invalid Email", { autoClose: 2000 });
    } else if (number.length < 10) {
      return toast.error("Invalid Number", { autoClose: 2000 });
    } else {
      history.push("/submitting");
      uploadFile();
    }
  };
  console.log(onSite);
  return (
    <div>
      <div className="header white-text">
      <img src={bg} className="background-image" alt="" />
        <h5 style={{fontWeight:"400"}} > Applying for </h5>
        <h1>{role}</h1>
      </div>
      <div className="ApplicationForm-page white-text">
        <div className="standard-div">
          <p>We require your basic details to proceed with the application. This information helps us evaluate your application.</p>
          <div className="ApplicationForm">
            <div>
              <div className="input-section two-input">
                <div>
                  <p>First Name</p>
                  <input
                    type="text"
                    className="smaller-input input-field"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div>
                  <p>Last Name</p>
                  <input
                    type="text"
                    className="smaller-input input-field"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="input-section">
                <p>Email</p>
                <input
                  className="input-field"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-section">
                <p>
                  Alternate Email{" "}
                  <span className="optional"> &nbsp;( Optional )</span>
                </p>
                <input
                  className="input-field"
                  type="email"
                  value={alternateEmail}
                  onChange={(e) => setAlternateEmail(e.target.value)}
                />
              </div>
              <div className="input-section two-input">
                <div>
                  <p>Phone Number</p>
                  <input
                    className="smaller-input input-field "
                    type="tel"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>

                <div>
                  <p>
                    Alternate Phone Number{" "}
                    <span className="optional"> &nbsp;( Optional )</span>
                  </p>
                  <input
                    className="smaller-input input-field"
                    type="tel"
                    value={alternateNumber}
                    onChange={(e) => setAlternateNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="input-section">
                <p>Correspondence Address</p>
                <textarea
                  className="textarea-style"
                  rows="2"
                  maxLength="200"
                  minLength="3"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="input-section">
                <div className="address-choice">
                  <p>Permanent Address</p>
                  <p>
                    {" "}
                    Same as Correspondence Address ? &nbsp;
                    <input
                      type="checkbox"
                      className="checkBox"
                      onClick={() => setIsSameAddress(!isSameAddress)}
                    />
                  </p>
                </div>
                <textarea
                  className="textarea-style"
                  rows="2"
                  maxLength="200"
                  minLength="3"
                  value={isSameAddress ? address : permanentAddress}
                  onChange={(e) => {
                    isSameAddress
                      ? setPermanentAddress(address)
                      : setPermanentAddress(e.target.value);
                  }}
                />
              </div>
              <div className="input-section">
                <p>What do you prefer ?</p>
                <div className="two-input">
                  <div className="radio-input">
                    <input
                      type="radio"
                      name="preference"
                      className="checkBox"
                      onClick={() => {
                        setOnSite(false);
                      }}
                    />
                    <p>Work From Home</p>&nbsp;&nbsp;
                  </div>
                  <div className="radio-input">
                    <input
                      type="radio"
                      name="preference"
                      className="checkBox"
                      onClick={() => {
                        setOnSite(true);
                      }}
                    />
                    <p>On-Site</p>&nbsp;&nbsp;
                  </div>
                </div>
              </div>
              <div className="input-section two-input">
                <div>
                  <p>City</p>
                  <input
                    type="text"
                    className="smaller-input input-field"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>
              <div className="input-section">
                <p>Why should we hire you? &nbsp;( Write in 200 words )</p>
                <textarea
                  className="textarea-style"
                  rows="4"
                  maxLength="200"
                  minLength="3"
                  value={answer1}
                  onChange={(e) => setAnswer1(e.target.value)}
                />
              </div>
              <div className="input-section">
                <p>
                  What skills do you think separates you from other candidates?
                  &nbsp;( Write in 200 words )
                </p>
                <textarea
                  className="textarea-style"
                  rows="4"
                  maxLength="200"
                  minLength="3"
                  value={answer2}
                  onChange={(e) => setAnswer2(e.target.value)}
                />
              </div>
            </div>
            <div style={{ height: "20px" }}></div>
            <p>Upload Your Resume/CV (File size less than 2MB)</p>
            <div className="input-section" style={{ height: "80px" }}>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={onFileChange}
                id="upload"
                style={{ display: "none" }}
              />
              <label className="upload-button" htmlFor="upload">
                Upload
              </label>
              {file ? file.name : ""}
            </div>
            <div className="submit-btn">
              <button className="btn" onClick={postData}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationForm;
