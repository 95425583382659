import React from "react"

const Severability =()=>{
    return(
        <div id="Severability">
             <h3 id="nine">9. Severability</h3>

<p>
  <ol type="i">
    <li>
      If a provision of these terms and conditions is determined by
      any court or other competent authority to be unlawful and/or
      unenforceable, the other provisions will continue in effect.
    </li>
    <li>
      If any unlawful and/or unenforceable provision of these terms
      and conditions would be lawful or enforceable if part of it
      were deleted, that part will be deemed to be deleted, and the
      rest of the provision will continue in effect.
    </li>
  </ol>
</p>

        </div>
    )
}
export default Severability