import React from "react"

const ThirdPartyRights =()=>{
    return(
        <div id="ThirdPartyRights">
            <h3 id="ten">10. Third party rights</h3>

<p>
  <ol type="i">
    <li>
      A contract under these terms and conditions is for our benefit
      and your benefit, and is not intended to benefit or be
      enforceable by any third party.
    </li>
    <li>
      The exercise of the parties' rights under a contract under
      these terms and conditions is not subject to the consent of
      any third party.
    </li>
  </ol>
</p>
        </div>
    )
}

export default ThirdPartyRights