import React, { useEffect } from "react";
import bp1 from "../../assets/images/bp-1.png";
import bp2 from "../../assets/images/bp-2.png";
import Item from "./item";
import Carousel from "react-elastic-carousel";
import { img1, img3, img4, img7 } from "../../assets/images";
import "./SwasnerResearch.css";
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const Research = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const items = [
    <img src={img7} className="RCImage" alt="" />,
    <img src={img3} className="RCImage" alt="" />,
    <img src={img4} className="RCImage" alt=""/>,
    <img src={img1} className="RCImage" alt=""/>,
  ];
  return (
    <div className="Research-page">
      <div className="ResearchAbout">
        <h3>Swasner : Next gen PPE</h3>
        <div className="research-content">
          <div className="research-item">
            <p>
              A smart personal Protective Equipment , capable of
              providing you a Personal space of purified air, making your life
              hasslefree away from those tight masks around your face.
            </p>
            <p>
              We have redesigned how conventional PPE should look,
              aiming to mark this as a “mainstream Protective Gear”. While
              renovating the PPE, we emphasised on
            </p>
            <ol type="1">
              <li>Design Aesthetics</li>
              <li>Ventilation and Perspiration problem inside the PPE</li>
              <li>Improving the longevity of product</li>
              <li>Ease of wearing</li>
            </ol>
          </div>
          <div className="research-item">
            <div className="carousel-wrapper">
              <Carousel breakPoints={breakPoints}>
                {items.map((item) => (
                  <Item key={item}>{item}</Item>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <div className="blueprint">
        <h5>Quaint but Enticing Design</h5>
        <p>
          Working in preProduction for more than 5 months, our
          design team was able to foster the quirkiest PPE you could have ever
          seen. Packed with features which users could only imagine of to be in
          a Protective Gear.
        </p>
        <p>
          From wearing an entire suit of plastic, we knew which part
          of the body needed to be protected. Cutting off the avoidable clothing
          we made Swasner not only economical but also fashionable. We didn't
          want to bring something obscure in front of people thus tried to make
          the design akin to just another hoodie.
        </p>
        <p>
          We kept the shoulder short for restriction free movement.
          To avoid any leaks our product comes with stretchable elastics on
          shoulder and waist cuts. In front we kept opening with gloves attached
          inside, to access the face without breaking the bio-bubble.
        </p>
        <h5>End of Sweating inside PPE</h5>
        <p>
          We knew how it felt to wear a complete airlocked suit the
          entire day, no alternative for the sweat to dry up. Thus getting
          inspired from our previous celebrated product “bubble Helmet : for non
          Invasive ventilation” to create a positive air pressure of purified
          air around the person for ease in respiration and reducing
          perspiration to rock bottom.
        </p>
        <p>
          We have procured the best in class fan in this price segment, with
          attractive specs on the datasheet
        </p>
        <table class="highlight specs">
          <thead>
            <tr>
              <th>Features</th>
              <th>Details</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>RPM (Rotation Per Minute)</td>
              <td>1200</td>
            </tr>
            <tr>
              <td>Bearing ( refers to the ball bearing inside the armature)</td>
              <td>
                SSO (Self Stabilising Oil Pressure Bearing) ,having a lifespan
                of 150,000 hours of running
              </td>
            </tr>
            <tr>
              <td>Acoustic Noise </td>
              <td>18.1 dB(A) ( Lower the decibel, lower is the noise) </td>
            </tr>
            <tr>
              <td>Max Static Pressure </td>
              <td>1.31 mmH20</td>
            </tr>
          </tbody>
        </table>
        <p>
          We performed several simulations to find the optimum
          position for the placement of the fan. We took into consideration the
          heat flow diagram and aerodynamics of our product.
        </p>
        <div className="sweating">
          <img className="sweating-image" src={bp1} alt="Research page pic 1" />
          <img className="sweating-image" src={bp2} alt="Research page pic 2" />
        </div>
        <h5>Immuned to wear and tear</h5>
        <p>
          Swasner was meant to last, and it was always our priority.
          We made prototypes wondering upon varied cloth materials . Factors we
          had to consider in this selection procedure were :
        </p>
        <ol>
          <li>
            <b>Imperviable</b> : First target was to make swasner an all weather
            product, and for this we had to make it with a cloth material being
            hydrophobic in nature.
          </li>
          <li>
            <b>Breathable</b> : This was our first barrier as both these
            features were contradictory to each other, so we tried to minimize
            the thickness of the fabric to the least. As our target audience
            were frontline workers, we needed to keep in mind about the heating
            issue inside Swasner.
          </li>
          <li>
            <b>Comfortable</b> : As there were regions where the fabric came in
            direct contact with the skin, we improvised to make it comfortable
            by adding another layer of fabric inside. We tested different
            options like : mesh cloth, Astar etc
          </li>
          <li>
            <b>Fashionable</b> : And our ultimate aim was to break the
            stereotype of PPE. This could have only been done by making Swasner
            a fashion statement. Our team after several iterations accepted the
            fabric which survived in all the requirements and still had an
            aesthetic feature to it.
          </li>
        </ol>
        <h5>Easy in , Easy Out</h5>
        <p>
          Those who have tried wearing a PPE suit, know that wearing
          it is itself another big Task. Thus we made this intelligent design in
          which you could wear it under 14 seconds.
        </p>
      </div>
    </div>
  );
};

export default Research;
