import React from "react"
const Breaches=()=>{
    return(
        <div id="Breaches">
             <h3 id="six">7. Breaches of these terms and conditions</h3>

<p>
  <ol type="i">
    <li>
      Without prejudice to our other rights under these terms and
      conditions, if you breach these terms and conditions in any
      way, or if we reasonably suspect that you have breached these
      terms and conditions in any way, we may:
    </li>
    <ol type="a"  className="typeAlist">
      <li>send you one or more formal warnings;</li>
      <li>temporarily suspend your access to our website;</li>
      <li>permanently prohibit you from accessing our website;</li>
      <li>
        block computers using your IP address from accessing our
        website;
      </li>
      <li>
        contact any or all of your internet service providers and
        request that they block your access to our website;
      </li>
      <li>
        commence legal action against you, whether for breach of
        contract or otherwise; and/or
      </li>
      <li>suspend or delete your account on our website.</li>
    </ol>
    <li>
      Where we suspend or prohibit or block your access to our
      website or a part of our website, you must not take any action
      to circumvent such suspension or prohibition or blocking
      (including without limitation creating and/or using a
      different account).
    </li>
  </ol>
</p>

        </div>
    )
}
export default Breaches