import React from "react"
 
const LimitedWarranties =()=>{
    return(
        <div id="LimitedWarranties">
            <h3 id="four">5. Limited Warranties</h3>

<p>
  <ol type="i">
    <li>We do not warrant or represent:</li>
    <ol type="a"  className="typeAlist">
      <li>
        the completeness or accuracy of the information published on
        our website;
      </li>
      <li>that the material on the website is up to date;o</li>
      <li>
        that the website or any service on the website will remain
        available.
      </li>
    </ol>
    <li>
      We reserve the right to discontinue or alter any or all of our
      website services, and to stop publishing our website, at any
      time in our sole discretion without notice or explanation; and
      save to the extent expressly provided otherwise in these terms
      and conditions, you will not be entitled to any compensation
      or other payment upon the discontinuance or alteration of any
      website services, or if we stop publishing the website.
    </li>
    <li>
      To the maximum extent permitted by applicable law and subject
      to Section 6.1, we exclude all representations and warranties
      relating to the subject matter of these terms and conditions,
      our website and the use of our website.
    </li>
  </ol>
</p>
        </div>
    )
}
export default LimitedWarranties