import React, { useEffect } from "react";
import bp1 from "../../assets/images/bp-1.png";
import bp2 from "../../assets/images/bp-2.png";
import bubble01 from "../../assets/images/bubble-01.jpg";
import bubble02 from "../../assets/images/bubble-02.png";
import bubble03 from "../../assets/images/bubble-03.jpg";
import bubble04 from "../../assets/images/bubble-04.png";
import bubble05 from "../../assets/images/bubble-05.jpg";
import bubble06 from "../../assets/images/bubble-06.png";
import bubble07 from "../../assets/images/bubble-07.jpg";
import bubble08 from "../../assets/images/bubble-08.jpg";
import bubble09 from "../../assets/images/bubble-09.png";
import bubble10 from "../../assets/images/bubble-10.png";
import Item from "./item";
import Carousel from "react-elastic-carousel";
import { img1, img3, img4, img7 } from "../../assets/images";
import "./BubbleHelmetResearch.css";
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const BubbleHelmetResearch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const items = [
    <img src={img7} className="RCImage" alt="" />,
    <img src={img3} className="RCImage" alt="" />,
    <img src={img4} className="RCImage" alt=""/>,
    <img src={img1} className="RCImage" alt=""/>,
  ];
  return (
    <div className="Research-page">
      <div className="ResearchAbout">
        <h3>Bubble Helmet Ventilator</h3>
        <div className="research-content">
          <div className="research-item">
            <p>
                India has an estimated 40,000 working ventilators, a number experts said will be inadequate in case there is a surge in Covid-19 infections that,
                in approximately 5% of the cases, sends patients to intensive care units (ICU) with acute breathing problems.The coronavirus attacks people's lungs,
                in some cases compromising their ability to breathe as they develop pneumonia. Ventilators, which deliver air to the lungs through a tube placed 
                in the windpipe, are crucial to keep these patients alive. Mechanical ventilators are creating more problems than it is solving for ARDS patients.
            </p>
            <br/>

            <div className="research-video embed-responsive embed-responsive-16by9">
                <iframe
                    className="yt-video embed-responsive-item"
                    src="https://www.youtube.com/embed/kxh-art-LfU"
                    allowfullscreen
                ></iframe>
            </div>

            <h5>What are the primary risks of a BVM/Mechanical ventilator?</h5>
            <p>
                Being viewed as a viable alternative to available professional care or delaying seeking professional care. This should be viewed as an educational 
                resource or a last resort option only unless it’s medical viability is proven otherwise. Community driven engineering efforts like this run the risk 
                of making it "too easy to be harmful" which would be a bad outcome. Using non-invasive interfaces (such as masks and mouth pieces) can create 
                aerosolized viruses infecting the medical personnel working in close proximity to the patient. This can lead to exponential transmission of the virus. 
                Usage should be restricted to places where aerosolized viruses are already abundant (lots of unmasked symptomatic individuals), COVID wards, or in isolation.
            </p>
            <br/>

            <h5>Solution</h5>
            <p>
                In the event that COVID-19 hospitalizations exhaust the availability of FDA/ICMR approved ventilators. This project documents the process of 
                converting the available compressed air and oxygen tanks at hospitals into a rudimentary non-invasive pressure support ventilator that could 
                help with breathing during respiratory distress. It's an evolving project, but in its current form, it most aligned with the definition of a 
                non-invasive pressure support BiPAP ventilator. <br/><br/>

                Bubble Helmets are used for patients suffering from Acute Respiratory Distress Syndrome (ARDS) which is also caused by COVID-19, the disease 
                caused by the 2019 novel coronavirus. The helmet surrounds the patient's entire head to supply oxygen and is sealed with a soft, airtight collar 
                that wraps around the neck.
            </p>

            <div className="solutions">
                <img src={bubble01} className="solutions-pic" />
                <div className="solution-stat">
                    <div className="solution-stats">
                        <h3>30%</h3>
                        <p>Better Servival Rate</p>
                    </div>
                    <div className="solution-stats">
                        <h3>25%</h3>
                        <p>Cost Effective</p>
                    </div>
                </div>
            </div>

            <h5>Tech Stack</h5>

            <div className="tech-stack">
            <ul>
                <h5>Ventilator</h5>
                <li>Air gas inlet</li>
                <li>Air reflux valve</li>
                <li>O2 reflux valve</li>
                <li>O2 metering valve</li>
                <li>Air metering valve</li>
                <li>Mixing Tank</li>
                <li>Mixed gas metering valve</li>
                <li>Safety valve</li>
                <li>Expiratory flow sensor</li>
                <li>Barometric Pressure sensor</li>
                <li>Calibration valve for inspiratory</li>
                <li>Pressure sensor</li>
                <li>Inspiratory pressure sensor</li>
                <li>Calibration valve for expiratory</li>
                <li>Expiratory pressure sensor</li>
                <li>O2 sensor</li>
                <li>Air pressure regulator</li>
                <li>O2 pressure regulator</li>
                <li>CO2 sensor</li>
                <li>Neonatal flow Sensor</li>
                <li>Nebulizer Mixer Valve</li>
                <li>Nebulizer Changeover valve</li>
                <li>Nebulizer Outlet</li>
            </ul>

            <ul className="bubble-helmet">
                <h5>Bubble Helmet</h5>
                <li>Components can be made efficiently with 3D printing and industrial grade tools.</li>
                <li>Medical Grade Plastic Material (Anyone can be used depending on the supply)</li>
                <li>Sustanat PC MG (Polycarbonate)</li>
                <li>FibreTuff PAPC: The 3D printing material will be used to make Class A, Class B and Class C medical devices.</li>
                <li>Medical grade Non-Latex Synthetic</li>
                <li>RubberNeopreneBuna-N</li>
            </ul>
            </div>
            
            <br/>

            <h5>Prototyping</h5>
            <div className="prototyping">
                <img className="prototyping-image" src={bubble02} alt="Prototyping 1" />
                <img className="prototyping-image" src={bubble03} alt="Prototyping 2" />
            </div>
            
        <br/>

        <h5>Pneumatic schematic of the Ventilator</h5>
        <ol>
          <li>
            Gas-mixture and gas-metering assembly. Gas from the supply lines enters the ventilator
            via the gasinlet connections for oxygen and air . Two nonreturn valves prevent one gas
            from returning to the supply line of the other gas. Mixing takes place in the tank and
            is controlled by two valves. Inspiratory flow is controlled by a third valve.
          </li>
          <li>
            Respiratory unit consists of safety valve and two nonreturn valves . In normal operation, 
            the safety valve is closed so that inspiratory flow is supplied to the patient's lungs. 
            During standby, the safety valve is open and enables spontaneous inspiration by the emergency 
            breathing valve. The emergency expiratory valve provides a second channel for expiration when 
            the expiratory valve is blocked.
          </li>
          <li>
            Expiratory unit consists of the expiratory valve and a nonreturn valve. The expiratory valve 
            is a proportional valve and is used to adjust the pressure in the patient circuit. In conjunction 
            with the spring-loaded valve of the emergency air outlet, the nonreturn valve prevents pendulum 
            breathing during spontaneous breathing.
          </li>
          <li>
            Expiratory flow sensor.
          </li>
          <li>
            Barometric pressure sensor. Conversion of mass flow to volume, body temperature and pressure saturated 
            (BTPS) requires knowledge of ambient pressure.
          </li>
          <li>
            Pressure measurement assembly. Pressure in the patient circuit is measured with two independent pressure sensors .
          </li>
          <li>
            Calibration assembly. The pressure sensors are regularly zero calibrated by connection to ambient pressure via 
            the two calibration valves.
          </li>
          <li>
            Oxygen sensor.
          </li>
          <li>
            Medication nebulizer assembly
          </li>
        </ol>

        <br/>

        <div className="prototyping">
            <img className="prototyping-image" src={bubble04} alt="Research page pic 1" />
            <img className="prototyping-image" src={bubble05} alt="Research page pic 2" />
        </div>

        <br/>

        <h5>Schematics of the helmet</h5>

        <div className="schematics">
            <img className="schematics-image" src={bubble06} alt="Schematics pic 1" />
            <img className="schematics-image" src={bubble07} alt="Schematics pic 2" />
        </div>

        <br/>

        <p>
            The primary function of the Bubble Helmet is to provide the patient with oxygen rich air 
            (as per the patient's requirement) and also at a higher atmospheric pressure (If required). <br/><br/>

            The Helmet is provided with Entry and Exit valves to facilitate the flow of air inside the Bubble Helmet. <br/><br/>

            Also two emergency holes are given in the plastic bubble acting as emergency valves to supply air to the patient using an ambu bag.
        </p>

        <div className="schematics">
            <img className="schematics-image" src={bubble08} alt="Schematics pic 1" />
            <img className="schematics-image" src={bubble09} alt="Schematics pic 2" />
        </div>

        <h5>Hardware Capabilities</h5>

        <div className="hardware">

        <ul >
            <h5>Ventilator</h5>
            <li>
                This design can have a programmable breathing rate (ex: 10-30 cycles/min.)
            </li>
            <li>
                It can have a programmed Positive End-Expiratory Pressure (PEEP).
            </li>
            <li>
                The Ventilator is physically able to hit a peak air pressure of up to 45 cm of H2O.
            </li>
            <li>
                PIP (Positive Inspiratory Pressure) : This gives the range of pressure we can have in the hyperbaric chamber (Bubble). 
                (Range: 12 to 30 cm of H20)
            </li>
            <li>
                Medication nebulizer assembly-It is easy and pleasant to breathe the medicine into your lungs this way. It is lacking in 
                many other ways, but it is possible that this limited functionality could be helpful when better care is not available. 
                It is also a starting point for making progress. Critical features can be added over time.
            </li>
        </ul>
        
        <br/>

        <ul >
            <h5>Bubble Helmet</h5>
            <li>
                The primary function of the Bubble Helmet is to provide the patient with oxygen rich air 
                (as per the patient's requirement) and also at a higher atmospheric pressure (If required).
            </li>
            <li>
                The Helmet is provided with Entry and Exit valves to facilitate the flow of air inside the Bubble Helmet.
            </li>
            <li>
                Also two emergency holes are given in the plastic bubble acting as emergency valves to supply 
                air to the patient using an ambu bag.
            </li>
            <li>
                The patient does not feel claustrophobic, which is a common drawback of face mask based ventilators.
            </li>
        </ul>

        <div className="prototyping">
            <img className="prototyping-image" src={bubble10} alt="Research page pic 1" />
        </div>
        </div>

        <h5>Why This?</h5>

        <p>
            Noninvasive ventilation (NIV) with a face mask is relatively ineffective at preventing endotracheal 
            intubation in patients with acute respiratory distress syndrome (ARDS). Delivery of NIV with a helmet 
            may be a superior strategy for these patients.Delivering NIV via a helmet interface-a transparent hood 
            that covers the entire head of the patient with a soft collar neck seal, confers several advantages over 
            face mask including improved tolerability and less air leak due to the helmet's lack of contact with the 
            face. Therefore, the helmet's design may allow increased titration of positive airway pressures without 
            substantial air leak. This could reduce intubation rates and extend the benefits of NIV to more patients 
            with ARDS. <br/><br/>

            The patient in turn feels less claustrophobic, spends less time at the ICU and can sooner get rid of the 
            ventilator than the traditional mask. <br/><br/>

            Ventilator Powered Bubble Helmet is a much needed rescue from the traditional masks and ventilators in 
            the time of a crisis.<br/><br/>

            Its ability to most of all create a nonclaustrophobic  environment for the patient, who is already under 
            life support is one of the greatest advancements in medical masks. Along with that the cost effectiveness 
            and advantage to the patients and doctors citing its unique shape and design is a winwin deal. COVID-19 
            crisis is amongstthe worst crisis ever faced by the modern world. Moreover the consequences of the pandemic 
            will be far more destructive than the current crisis. It is essential to savethe resources available for any 
            future need. The product tick these boxes and since are 3-D printed they are sustainable, and cheap. This 
            product thereby, stands toe-toe in facing the challenges posed by this pandemic.
        </p>

          </div>
        </div>
      </div>
    </div>
  );
};

export default BubbleHelmetResearch;
