import React, { useEffect } from "react";
import { FacebookProvider, EmbeddedPost } from "react-facebook";
import { Tweet } from "react-twitter-widgets";
/* import InstagramEmbed from "react-instagram-embed"; */
import "./home.css";
import "./media.css";
import aos from "aos";

const MediaAndCollaborations = () => {
  useEffect(() => {
    aos.init({ duration: 1000, offset: 300 });
  });
  return (
    <div className="Media">
      <h4 data-aos="fade-up">Social Media</h4>
      <div className="media-container">
        <div className="media-post" data-aos="fade-up">
          <Tweet tweetId="1307114999937105920" />
        </div>
        <div
          className="media-post"
          data-aos="fade-up"
          style={{ display: "flex", alignItems: "center" }}
        >
          <FacebookProvider appId="345856597029980">
            <EmbeddedPost
              href="https://www.facebook.com/EduMinOfIndia/photos/a.1896118983733931/3681335495212262"
              width="350"
            />
          </FacebookProvider>
        </div>
      </div>
      <dv></dv>
    </div>
  );
};

export default MediaAndCollaborations;
