import React from "react"

const OurDetails=()=>{
    return(
        <div id="OurDetails">
 <h3 id="thirteen">13. Our details</h3>
            <ol type="i">
              <li>
                <b>
                  This website is owned and operated by AUUM PLATFORMS PRIVATE
                  LIMITED.
                </b>
              </li>
              <li>
                <b>
                  We are registered in India, CIN U29100OR2020PTC034761 and our
                  registered office is at
                  <i>
                    Plot No-339/4899, Goutam Nagar Bhubaneswar Khordha OR 751014
                    IN
                  </i>
                </b>
              </li>
            </ol>
        </div>
    )
}

export default OurDetails