import React, { useEffect } from "react";
import { Link as Link1 } from "react-router-dom";
import aos from "aos";
import "aos/dist/aos.css";
import { img1 , flipped } from "../../assets/images/index";
import "./product.css";

const Work = () => {
  useEffect(() => {
    aos.init({ duration: 1500, offset: 200 });
  });
  return (
    <div className="product-container">
      <div className="product" data-aos="fade-up">
      <div className=" background " >
        <img src={flipped} className="product-image"  alt="" />
      </div>
        <div className="product-image-container">
          <Link1 to="/swasner">
            <img src={img1} className="product-image" alt="Product" />
          </Link1>
        </div>
        <div className="product-content">
          <Link1
            to="/swasner"
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <h4 className="product-name" >Swasner PPE</h4>
          </Link1>
          <h5 className="product-desc">
            End of Sweating with the modern ventilated PPE.
          </h5>
          <div className="price-reveal-contain">
            {/* <span className>
              <img src={strike} className="price-reveal-strike" alt="strikethrough price reveal"></img>
            </span> */}
            <h2 classname="price-reveal" style={{textDecoration: "line"}}>
               <strike style={{color: "white"}}><span style={{color:"red"}}>6499/-</span></strike><text>  5499/-*</text>
            </h2>
          </div>
          <div className="btn-container">
            <Link1 to="/orderpage">
              <button className="btn swasner-page-btn">Pre-Order Now</button>
            </Link1>
            <Link1 to="/swasner">
              <button className="know-btn">Know More</button>
            </Link1>
            <span>
              <p className="price-reveal-subtext">*special price for first 100 pre-orders only</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
