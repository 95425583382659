import React from "react";
import {BsChevronRight} from "react-icons/bs"
import { Link } from "react-router-dom";

const JobCard = ({id,title,domain,type,postedOn}) => {
  return (
    <Link to={"/jobdescription/"+id} style={{textDecoration:"none"}}>
      <div className="job-card">
        <div>
        <h5 className="posted-on">Posted on {postedOn}</h5>
        <h4 className="job-title">{title}</h4>
        <h5 className="sub-text">{domain}</h5>
        <h5 className="sub-text type">{type}</h5>
        </div>
      
       <BsChevronRight className="icon"/>
       
      </div>
    </Link>
  );
};

export default JobCard;
