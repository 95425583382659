import React from "react"

const EntireAgreement =()=>{
    return(
        <div id="EntireAgreement">
          <h3 id="eleven">11. Entire agreement</h3>

<p>
  <ol type="i">
    <li>
      Subject to <b>Section 6 - i</b>, these terms and conditions,
      together with our privacy and cookies policy, shall constitute
      the entire agreement between you and us in relation to your
      use of our website and shall supersede all previous agreements
      between you and us in relation to your use of our website.
    </li>
  </ol>
</p>
        </div>
    )
}

export default EntireAgreement