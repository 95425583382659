import React, { useEffect } from "react";
import IntroVideo from "../../assets/video/intro.mp4" ;
import team from "../../assets/images/team.jpg"
import { FaAngleDown } from "react-icons/fa";
import { Link as Link2 } from "react-scroll";
import Aos from "aos";
import "aos/dist/aos.css";
import MediaAndCollaborations from "./MediaAndCollaborations";
import "./home.css";
import Product from "../../components/Product/Product";

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 1000, offset: 100 });
    window.scroll(0, 0);
  });

  return (
    <div
      className="home black white-text"
      style={{ textAlign: "center", backgroundColor: "black" }}
      id="about-us"
    >
      <video  muted autoPlay  className="video">
        <source src={IntroVideo} type="video/mp4" />
      </video>

      <Link2
        activeClass="active"
        to="work"
        spy={true}
        smooth={true}
        offset={-50}
        duration={500}
        style={{ cursor: "pointer" }}
      >
        <div class="arrow bounce">
          <FaAngleDown />
        </div>
      </Link2>
      <Product />
      <div className="about" data-aos="fade-up">
        <div className="about-image-container">
          <img
            src={team}
            alt="about"
            className="aboutImage"
          />
        </div>
        <div className="aboutContainer">
          <h4 className="heading">About Us</h4>
          <p className="aboutContent">
            We are a startup working out of Bhubaneswar, focused on creating
            promising & new applications of emerging technologies for consumers
            and enterprise. We are recognised by <b> “Startup India” </b>
            initiative, <b>DPIIT</b> and are incubated by{" "}
            <b>Atal Incubation Centre </b>
            Nalanda.
          </p>
        </div>
      </div>
      <div className="mission" data-aos="fade-up" >
        <h4 className="mission-heading">Our Mission</h4>
        <p className="mission-content">
          " To create the best and most innovative products with the purpose to
          support people to fight new challenges."
        </p>
      </div>
      {/* <Team/> */}
      <MediaAndCollaborations />
    </div>
  );
};
export default Home;
