import React from 'react'
import "./Products.css"
import Product from "../../components/Product/Product"
import {bg} from "../../assets/images/index";

const Products = () => {
    return (
        <div className="products-page" >
        <div className="header">
        <img src={bg} className="background-image" alt="" />
            <h4 className="heading" >Our Products</h4>
        </div>
         <Product/>
        </div>
    )
}

export default Products
