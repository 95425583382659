import React, { useState, useEffect } from "react";
import "./careers.css";
import { Link, useParams } from "react-router-dom";
import { bg } from "../../assets/images/index"
import { AiOutlineArrowLeft } from "react-icons/ai";
import LoadingScreen from "../Loading/LoadingScreen";
import { db } from "../../firebase/Firebase";

const JobDescription = () => {
  const { jobid } = useParams();
  const [job, setJob] = useState({ name: null });
  useEffect(() => {
    window.scroll(0, 0);
    const findJob = () => {
      db.collection("JobOpenings")
        .doc(jobid)
        .get()
        .then((snapshot) => setJob(snapshot.data()));
    };
    findJob();
  }, [jobid]);
  if (job.name === null) {
    return <LoadingScreen />;
  } else {
    return (
      <div className="job-page white-text">
        <div className="header">
        <img src={bg} className="background-image" alt="" />
          <h1>{job.title}</h1>
          <h5 className="gray-text">{job.domain}</h5>
          <div className="Apply-btn-section">
            <Link to={"/jobapplicationform/" + job.title}>
              <button className="job-btn btn ">Apply now</button>
            </Link>
          </div>
        </div>
        <div className="page-content">
          <Link to="/careers" style={{ textDecoration: "none" }}>
            <h5 className="back">
              <AiOutlineArrowLeft /> Back to Careers{" "}
            </h5>
          </Link>
          <div className="standard-div ">
            <h4>Role Overview</h4>
            <p>{job.overview}</p>
          </div>
          <div className="standard-div">
            <h4>Key Responsibilities</h4>
            <ul>
              {job.responsibilities.map((item) => {
                return <li>{item}</li>;
              })}
            </ul>
          </div>
          <div className="standard-div">
            <h4>What we expect</h4>
            <ul>
              {job.expectations.map((item) => {
                return <li>{item}</li>;
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
};

export default JobDescription;
