import firebase from "firebase";
import 'firebase/storage'

var firebaseConfig = {
  apiKey: "AIzaSyDRb7fj4SUnZG1NpdnnP2ufTyjjGNLUu04",
  authDomain: "website-d4690.firebaseapp.com",
  projectId: "website-d4690",
  storageBucket: "website-d4690.appspot.com",
  messagingSenderId: "449738409238",
  appId: "1:449738409238:web:9bcb06ac3debdca1cfbc37",
  measurementId: "G-3060RKRHBQ",
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

const db = firebase.firestore();

export { db , storage };
