export const img1 = require("./1.png").default;
export const img2 = require("./2.png").default;
export const img3 = require("./3.png").default;
export const img4 = require("./4.png").default;
export const img5 = require("./5.png").default;
export const img6 = require("./6.png").default;
export const img7 = require("./7.png").default;
export const bulb = require("./bulb.jpg").default;
export const working = require("./working.png").default;
export const short_logo = require("./auum_short_logo.png").default;
export const job = require("./job.png").default;
export const flipped = require("./flipped.png").default;
export const bg = require("./bg.png").default;

