import React , {useState , useEffect} from "react";
import {short_logo} from "../../assets/images/index"
import { useHistory, Link} from "react-router-dom";
const Thankyou = () => {
    const [seconds, setSeconds] = useState(5)
    const history = useHistory()
    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
          } else {
            setSeconds(0);
          }
    },[seconds])
    useEffect(() => {
        setTimeout(() => {
            history.push('/')
        }, 5000);
    }, [history])
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "80vw",
        margin: "0 auto",
      }}
    >
      <img style={{ width: "200px", margin: "30px" }} src={short_logo} alt="" />
      <h1 style={{ color: "white" }}>Thank You</h1>
      <h5 style={{ color: "white" }}>
       Our Team will get back to you soon.
      </h5>
      <p className="white-text">You are being redirected to <Link to="/" style={{color:"#1bb1dc", textDecoration:"none"}} >Home Page</Link> in {seconds} seconds ...</p>
    </div>
  );
};

export default Thankyou;
