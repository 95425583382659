import React, { useState, useEffect } from "react";
import aos from "aos";
import "aos/dist/aos.css";
import { bg } from "../../assets/images/index"
import { FaLocationArrow } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./contact.css";
import firebase from "firebase";
import { db } from "../../firebase/Firebase";
toast.configure();
const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function postMessage(e) {
    e.preventDefault();
    if (!name || !email || !message) {
      toast.error("Enter all the fields.", { autoClose: 2000 });
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      return toast.error("Invalid Email", { autoClose: 2000 });
    } else {
      db.collection("Messages")
        .add({
          name: name,
          email: email,
          time: firebase.firestore.FieldValue.serverTimestamp(),
          message: message,
        })
        .then(() => {
          toast.success("Message sent.", { autoClose: 2000 });
        })
        .catch((err) => {
          alert(err.message);
        });
      setName("");
      setEmail("");
      setMessage("");
    }
  }

  useEffect(() => {
    aos.init({ duration: 1000, offset: 200 });
    window.scroll(0, 0);
  });
  return (
    <div className="contact-page">
      <div className="header">
      <img src={bg} className="background-image" alt="" />
        <h4 className="heading">GET IN TOUCH</h4>
      </div>
      <div className="contact white-text" data-aos="fade-up">
        <div className="form">
          <h5
            style={{
              color: "#bdbdbd",
              margin: "35px 0px",
              fontFamily: "'Open Sans', sans-serif",
            }}
          >
            Please fill out the form below and we will get back to you as soon
            as possible.
          </h5>
          <div style={{ textAlign: "left", width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <input
                type="text"
                className="input1"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                className=" input1"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <input
              type="text"
              className=" input2"
              placeholder="Message"
              style={{ height: "100px" }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>

          <button type="submit" className="btn " onClick={postMessage}>
            Send Message
          </button>
        </div>
        <div className="info">
          <h5 id="contactInfo" className="heading">Contact Info</h5>
          <p className="infoPara">
            <span className="icons">
              <FaLocationArrow />
            </span>
            <span className="infolabel"> Address : </span>
            <span className="infoText">Bhubaneswar, Odisha</span>
          </p>
          <p className="infoPara">
            <span className="icons">
              <FaPhoneAlt />
            </span>
            <span className="infolabel"> Phone : </span>
            <span className="infoText">
           
              +91 8249158968
              
            </span>
          </p>
          <p className="infoPara">
            <span className="icons">
              <FiMail />
            </span>
            <span className="infolabel"> Email : </span>
            <span className="infoText">
              info@auum.in
            </span>
          </p>
        </div>
         <a
        href="https://wa.me/918249158968"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"> </i>
      </a>
      </div>
    </div>
  );
};

export default Contact;
