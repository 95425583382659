import React from "react"

const LawAndJurisdiction =()=>{
    return(
        <div id="LawAndJurisdiction">
        
        <h3 id="twelve">12. Law and jurisdiction</h3>

<p>
  <ol type="i">
    <li>
      These terms and conditions shall be governed by and construed
      in accordance with the jurisdiction of India.
    </li>
    <li>
      Any disputes relating to these terms and conditions shall be
      subject to the jurisdiction of the courts of India.
    </li>
  </ol>
</p>
        </div>
    )
}

export default LawAndJurisdiction