import React from 'react'
import OrderForm from './OrderContact'

export default function OrderPage() {
    return (
        <div style={{
            height:"100vh",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
        }} >
            <OrderForm/>
        </div>
    )
}
