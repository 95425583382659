import React, { useState } from "react";
import { FaAngleUp} from "react-icons/fa";
import "./NavBar.css";
const GoToTop=()=> {
  const [visibilty, setVisibilty] = useState(false);
 
  const changeButton =()=>{
    if (window.scrollY >= 600) {
        setVisibilty(true)
      } else {
        setVisibilty(false)
      }   
  }
  window.addEventListener("scroll", changeButton);
if(!visibilty){
    return false
}
const goTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="gotoTop" onClick={goTop} >
      <FaAngleUp/>
    </div>
  );
}

export default GoToTop