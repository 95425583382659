import React from "react"
const Limitations =()=>{
    return(
        <div id="Limitations">
            
            <h3 id="five">6. Limitations and exclusions of liability</h3>

            <p>
              <ol type="i">
                <li>Nothing in these terms and conditions will:</li>
                <ol type="a"  className="typeAlist">
                  <li>
                    limit or exclude any liability for death or personal injury
                    resulting from negligence;
                  </li>
                  <li>
                    limit or exclude any liability for fraud or fraudulent
                    misrepresentation;
                  </li>
                  <li>
                    limit any liabilities in any way that is not permitted under
                    applicable law; or
                  </li>
                  <li>
                    exclude any liabilities that may not be excluded under
                    applicable law.
                  </li>
                </ol>
                <li>
                  The limitations and exclusions of liability set out in this
                  Section 12 and elsewhere in these terms and conditions:
                </li>
                <ol type="a"  className="typeAlist">
                  <li>
                    are subject to <b>Section i</b>; and
                  </li>
                  <li>
                    govern all liabilities arising under these terms and
                    conditions or relating to the subject matter of these terms
                    and conditions, including liabilities arising in contract,
                    in tort (including negligence) and for breach of statutory
                    duty, except to the extent expressly provided otherwise in
                    these terms and conditions.
                  </li>
                </ol>
                <li>
                  To the extent that our website and the information and
                  services on our website are provided free of charge, we will
                  not be liable for any loss or damage of any nature.
                </li>
                <li>
                  We will not be liable to you in respect of any losses arising
                  out of any event or events beyond our reasonable control.
                </li>
                <li>
                  We will not be liable to you in respect of any business
                  losses, including (without limitation) loss of or damage to
                  profits, income, revenue, use, production, anticipated
                  savings, business, contracts, commercial opportunities or
                  goodwill.
                </li>
                <li>
                  We will not be liable to you in respect of any loss or
                  corruption of any data, database or software.
                </li>
                <li>
                  We will not be liable to you in respect of any special,
                  indirect or consequential loss or damage.
                </li>
                <li>
                  You accept that we have an interest in limiting the personal
                  liability of our officers and employees and, having regard to
                  that interest, you acknowledge that we are a limited liability
                  entity; you agree that you will not bring any claim personally
                  against our officers or employees in respect of any losses you
                  suffer in connection with the website or these terms and
                  conditions (this will not, of course, limit or exclude the
                  liability of the limited liability entity itself for the acts
                  and omissions of our officers and employees).
                </li>
                
              </ol>
            </p>

        </div>
    )
}
export default Limitations