import React from 'react'

const LoadingText = ({text}) => {
    return (
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"150px",width:"100%",margin:"0 auto"}}>
            <h3 style={{color:"gray"}}>{!text?"Loading...":text}</h3>
        </div>
    )
}

export default LoadingText
